// used to binding view
export class User {
  id?: string;
  name: string;
  email: string;
  role?: Array<any>;
  avatar?: string;
}

// used to map db
export class UserModel {
  id: string;
  // uid: string;
  name: string;
  email: string;
  role: Array<any>;
  regions: Array<any>; // used to search
  avatar?: string;
  createdAt?: any;
  createdBy?: string;
  updatedAt?: any;
  updatedBy?: string;

  isAdmin?: boolean; // NEED TO REMOVE - after the rules is completed
}
