import { Injectable } from '@angular/core';

import { FirebaseService } from '@hicity/hicity-cms-lib';

@Injectable({
  providedIn: 'root'
})
export class PreferencesService {
  constructor(
    private af: FirebaseService,
  ) {
  }

  getPreferences() {
    return this.af.get('preferences', 'initial');
  }

}
