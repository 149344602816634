import { NgModule } from '@angular/core';
import { CommonModule, DatePipe, DecimalPipe, TitleCasePipe } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { ScrollDispatcher } from '@angular/cdk/overlay';
// import { TranslateModule } from '@ngx-translate/core';
import { NgZorroAntdModule, NZ_I18N, zh_CN } from 'ng-zorro-antd';
import {
  ContentEditorModule, TableModule, UploadVideoModule, UploadImageModule,
  ImageCropperModule, BusinessHourSettingModule, GraphicModule, SearchInputModule, AccountModule,
  HideUnauthorizedModule, PermissionRoleModule, PermissionUserModule, OrderbyPipe, UniquePipe, FilterPipe, GroupbyPipe, OrderbyModule, LocationModule
} from '@hicity/hicity-cms-lib';
import { HaversineService } from 'ng2-haversine';
import { QRCodeModule } from 'angularx-qrcode';
import { DateFormatPipe } from './pipes/dateformat.pipe';
import { OrderColorPipe } from './pipes/order-color.pipe';
import { FoodDiscountPipe } from './pipes/fooddiscount.pipe';

@NgModule({
  declarations: [
    DateFormatPipe,
    FoodDiscountPipe,
    OrderColorPipe,
  ],
  imports: [
    RouterModule,
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    DragDropModule,
    ScrollingModule,
    QRCodeModule,
    NgZorroAntdModule,
    ContentEditorModule,
    TableModule,
    UploadVideoModule,
    UploadImageModule,
    ImageCropperModule,
    BusinessHourSettingModule,
    GraphicModule,
    SearchInputModule,

    AccountModule,
    LocationModule,
    HideUnauthorizedModule,
    PermissionRoleModule,
    PermissionUserModule,

    OrderbyModule,
  ],
  exports: [
    RouterModule,
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    DragDropModule,
    ScrollingModule,
    QRCodeModule,
    NgZorroAntdModule,
    ContentEditorModule,
    TableModule,
    UploadVideoModule,
    UploadImageModule,
    ImageCropperModule,
    BusinessHourSettingModule,
    GraphicModule,
    SearchInputModule,

    // TranslateModule,

    AccountModule,
    LocationModule,
    HideUnauthorizedModule,
    PermissionRoleModule,
    PermissionUserModule,

    OrderbyModule,

    // OrderstatusPipe
    DateFormatPipe,
    FoodDiscountPipe,
    OrderColorPipe,
  ],
  providers: [
    { provide: NZ_I18N, useValue: zh_CN },
    DatePipe,
    DecimalPipe,
    TitleCasePipe,
    OrderbyPipe,
    GroupbyPipe,
    UniquePipe,
    FilterPipe,
    ScrollDispatcher,
    HaversineService,
    // OrderstatusPipe
    DateFormatPipe,
    FoodDiscountPipe,
    OrderColorPipe,
  ]
})
export class SharedModule { }
