export const environment = {
  production: true,
  firebaseConfig: {
    apiBaseURL: 'https://us-central1-calaren-c1-d3cc2.cloudfunctions.net',
    apiKey: 'AIzaSyAEsogcqfGHDl4dOfSO3oSlx0Yrv4VNW1o',
    authDomain: 'calaren-c1-d3cc2.firebaseapp.com',
    databaseURL: 'https://calaren-c1-d3cc2.firebaseio.com',
    projectId: 'calaren-c1-d3cc2',
    storageBucket: 'calaren-c1-d3cc2.appspot.com',
    messagingSenderId: '772554357311'
  },
  defaultCityConfig: {
    apiBaseURL: 'https://us-central1-hicity-montreal-ba4ee.cloudfunctions.net',
    apiKey: 'AIzaSyBpMahjZeuF2up7H1zkRcLr9u9ME7AK_nI',
    authDomain: 'hicity-montreal-ba4ee.firebaseapp.com',
    databaseURL: 'https://hicity-montreal-ba4ee.firebaseio.com',
    projectId: 'hicity-montreal-ba4ee',
    storageBucket: 'hicity-montreal-ba4ee.appspot.com',
    messagingSenderId: '18177153996',
  },
  groupBuyingUrl: 'https://groupon-cms.hicity.world',
  estateUrl: 'http://estate-cms.hicity.world',
  visaUrl: 'http://visa-cms.hicity.world',
  carUrl: 'http://car-cms.hicity.world',
  communityUrl: 'http://circle-cms.hicity.world',
  mallUrl: 'http://mall-cms.hicity.world',
  hicityUrl: 'http://cms.hicity.world',
  appWebBaseUrl: 'https://app.hicity.world',
};
// ！这是PPRO环境！

