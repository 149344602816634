import { Injectable } from '@angular/core';
import { ConfigurationProvider, Configure } from '@hicity/hicity-cms-lib';
import { environment } from 'src/environments/environment';

@Injectable({ providedIn: 'root' })
export class ConfigurationStore {
  private internalConfig: Configure;

  setConfig(config: Configure) {
    this.internalConfig = config;
  }

  getConfig() {
    return this.internalConfig;
  }
}

@Injectable({
  providedIn: 'root'
})
export class ConfigFromApp implements ConfigurationProvider {

  constructor(private configStore: ConfigurationStore) { }

  get config(): Configure {
    return this.configStore.getConfig();
  }

  get baseConfig(): Configure {
    return environment.firebaseConfig;
  }
}

export function initApp(configurationStore: ConfigurationStore) {
  return () => {
    return new Promise<void>(resolve => {
      setTimeout(() => {
        const currentLocation = JSON.parse(localStorage.getItem('currentLocation'));
        if (currentLocation) {
          configurationStore.setConfig(currentLocation.config);
        } else {
          configurationStore.setConfig(environment.defaultCityConfig);
        }
        resolve();
      }, 2000);
    });
  };
}

// export function initAppWithHttp(configurationStore: ConfigurationStore, httpClient: HttpClient) {
//   return () => {
//     return httpClient
//       .get('https://my-super-url-to-get-the-config-from')
//       .toPromise()
//       .then(config => {
//         configurationStore.setConfig(config);
//       });
//   };
// }
