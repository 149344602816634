import { Injectable } from '@angular/core';

import { User } from 'src/app/shared/models/user';
import { AuthenticationService, FirebaseService, LanguageService } from '@hicity/hicity-cms-lib';

import { map, first } from 'rxjs/operators';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class OwnedRestaurantService {
  private currentUser: User;
  private _userOwnedRestaurant: any;
  private userOwnedRestaurantSubject: any;

  constructor(
    private af: FirebaseService,
    private authentication: AuthenticationService,
    private lang: LanguageService,
  ) {
    this.authentication.currentUser().subscribe(x => this.currentUser = x);
    this.userOwnedRestaurantSubject = new BehaviorSubject<any>(this._userOwnedRestaurant);
  }

  public get userOwnedRestaurantValue(): any {
    return this.userOwnedRestaurantSubject.value;
  }

  public userOwnedRestaurant(): Observable<any> {
    if (!this._userOwnedRestaurant) {
      return this.getUserOwnedRestaurant();
    }

    return this.userOwnedRestaurantSubject.asObservable().pipe(first());
  }

  private getUserOwnedRestaurant() {
    const collection = this.lang.getPath(`restaurant`);
    const currentUserEmail = this.currentUser && this.currentUser.email ? this.currentUser.email : (this.authentication.currentUserValue ? this.authentication.currentUserValue.email : '');
    const restaurants = this.af.listCondition(collection, [{ key: 'owner', operator: '==', value: currentUserEmail }]);
    return restaurants.pipe(map(restaurant => {
      let _restaurant = null;
      if (restaurant.length !== 0) {
        _restaurant = {
          id: restaurant[0].id,
          owner: restaurant[0].owner,
          name: restaurant[0].name,
          phone: restaurant[0].phoneNumber,
          address: restaurant[0].address,
          openDays: restaurant[0].openDays
        };
      }

      this._userOwnedRestaurant = _restaurant;
      this.userOwnedRestaurantSubject.next(_restaurant);
      return _restaurant;
    }), first());
  }

  clearOwnedRestaurant() {
    this._userOwnedRestaurant = null;
    this.userOwnedRestaurantSubject.next(null);
  }
}
