import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';

import { TranslateService } from '@ngx-translate/core';
import { NzMessageService } from 'ng-zorro-antd';
import { AuthenticationService, LanguageService, LocationService, LoginService, Role } from '@hicity/hicity-cms-lib';

import { User } from '../shared/models/user';

import { ConfigurationStore } from '../shared/services/configuration';
import { OwnedRestaurantService } from '../shared/services/restaurant/owned-restaurant.service';

import { mergeMap } from 'rxjs/operators';

import { environment } from '../../environments/environment';
import * as _ from 'lodash';
const isProd = environment.production;

@Component({
  selector: 'app-navbar',
  encapsulation: ViewEncapsulation.None,
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss']
})
export class NavbarComponent implements OnInit {
  year: number;

  spinning = false;
  spinningTip: string;
  displayNavbar: boolean;

  isCollapsed = false;
  currentRoute: any;
  currentUser: User = new User();
  public isProd = isProd;
  unpaiOrder = [];
  refundOrder = [];

  cityListVisible = false;
  showAllCity = false;
  constants: any;
  popularList: any;
  currentLocation: any;
  loading: boolean;
  loadingTip: string;
  selectedLanguage: any;
  restaurantId: string;
  toneOnce:boolean = false;

  constructor(
    private router: Router,
    private translate: TranslateService,
    private message: NzMessageService,
    private login: LoginService,
    public language: LanguageService,
    private locationService: LocationService,
    private authService: AuthenticationService,
    private configurationStore: ConfigurationStore,
    private ownedrestaurantService: OwnedRestaurantService,
    
  ) {
    this.spinning = true;
    this.spinningTip = '加载中，请耐心等待...';
    this.toneOnce = localStorage.getItem('ToneOnce') == 'true';
    this.authService.currentUser().subscribe(x => {
      this.spinning = false;

      if (x) {
        this.currentUser = x;
        this.ownedrestaurantService.userOwnedRestaurant().subscribe(v => {
          const ownedRestaurant = v;
          this.restaurantId = ownedRestaurant ? ownedRestaurant.id : undefined;
        });
      } else {
        this.router.navigate(['login']);
      }
    }, error => {
      console.log(error);
      this.router.navigate(['login']);
    });
  }

  ngOnInit() {
    this.displayNavbar = true;
    this.currentRoute = this.router.url;
    this.detectRouterChange();

    this.year = new Date().getFullYear();
    this.isProd = environment.production;

    if (this.language.currentLanguageValue) {
      this.selectedLanguage = this.language.currentLanguageValue;
    } else {
      this.selectedLanguage = this.language.languages[0];
    }

    this.popularList = new Array<any>();
    this.locationService.getLocations().pipe(mergeMap((locations: any) => {
      // 获取城市列表
      this.locationService.setLocationsValue(locations || []);

      // 获取热门城市列表
      this.popularList = locations.filter(v => v.popular);
      return this.locationService.currentLocation;
    })).subscribe(current => {
      this.currentLocation = current;
      if (!this.currentLocation) {
        this.locationService.setCurrentLocation(this.popularList[0]);
      }
    });
  }

  switch(boolean) {
    this.toneOnce = boolean;
    localStorage.setItem('ToneOnce', JSON.stringify(this.toneOnce));
  }

  private detectRouterChange() {
    this.router.events.subscribe(val => {
      if (val instanceof NavigationEnd) {
        const _path = val.url.split('/');
        this.currentRoute = _path.length > 1 ? '/' + _path[1] : '/';
      }
    });
  }

  onLanguageSelected(language: any) {
    this.selectedLanguage = language;
    this.language.setCurrentLanguage(this.selectedLanguage);
    this.translate.use(this.selectedLanguage.lang).subscribe(() => {
      window.location.reload();
    });
  }

  navigate(path: string, id?: string, queryParams?: any, isCollapsed?: boolean) {
    // this.currentRoute = path;
    this.isCollapsed = isCollapsed;
    if (id && queryParams) {
      this.router.navigate([path, id], { queryParams: queryParams });
    } else {
      this.router.navigate([path]);
    }
  }

  onCurrentCityChange(selectedCity: any) {
    this.cityListVisible = false;

    this.authService.currentUser().subscribe(async currentUser => {
      if (currentUser) {
        const prevCity = _.cloneDeep(this.locationService.currentLocationValue);

        const index = currentUser.role.findIndex(role => role.cityId === selectedCity.cityId);
        if (currentUser.role.every(role => ![Role.SystemAdmin, Role.SuperAdmin].includes(role.roleId)) && index === -1) {
          this.currentLocation = prevCity;
          this.locationService.setCurrentLocation(prevCity);
          this.message.error(`无权访问所选城市，请选择其他区域或联系管理员！`);
          return;
        }

        try {
          this.loading = true;
          this.loadingTip = '正在切换城市，请耐心等待...';
          this.displayNavbar = false;
          this.locationService.setCurrentLocation(selectedCity);

          this.configurationStore.setConfig(selectedCity.config);
          await this.login.loginWithToken();
          this.loading = false;

          this.showAllCity = false;
          this.displayNavbar = true;

          this.currentRoute = '/';
          this.router.navigate(['']);
        } catch (error) {
          if (prevCity) { this.locationService.setCurrentLocation(JSON.stringify(prevCity)); }
          console.log(error);
        }
      }

    });
  }

  onCityListVisibleChange() {
    this.cityListVisible = !this.cityListVisible;
  }

  onShowAllCity() {
    this.cityListVisible = false;
    this.showAllCity = true;
  }

  logout() {
    this.loading = true;
    this.loadingTip = '正在登出，请耐心等待...';
    this.authService.clearCurrentUser().then(() => {
      window.location.reload();
      this.router.navigate(['login']);
    }).catch(error => {
      console.log(error);
    }).finally(() => {
      this.loading = false;
    });
  }

}