import { Component } from '@angular/core';

import { ShareProvider } from './shared/providers/share/share';
import { PreferencesService } from './shared/services/global/preferences.service';

import { take } from 'rxjs/operators';

import * as moment from 'moment';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  constructor(
    private shareProvider: ShareProvider,
    private preferencesService: PreferencesService,
  ) {
    moment.locale('zh-cn');
    // 获取城市配置信息
    this.preferencesService.getPreferences().pipe(take(1)).subscribe(preference => {
      if (!preference) preference = {};

      this.shareProvider.qst = +preference.qst || 0;
      this.shareProvider.gst = +preference.gst || 0;
      this.shareProvider.qstLabel = preference.qstLabel || 'QST';
      this.shareProvider.gstLabel = preference.gstLabel || 'GST';
      this.shareProvider.tipCity = preference.tipCity ? true : false;
      this.shareProvider.currencySymbol = preference.currencySymbol || '$';
    }, err => console.error(err));
  }
}
