import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ShareProvider {

  // 区域税
  public qst: number;
  public gst: number;
  public qstLabel: string;
  public gstLabel: string;
  public tipCity: boolean;
  public currencySymbol: string;

  constructor(
    public http: HttpClient,
  ) {
  }

  private toPercentage(number: Number = 0) {
    var _numberStr = number.toString()
    var _percentageStr = _numberStr + '00'
    var _dotIndex = _percentageStr.indexOf('.')

    // 有小数
    if (_dotIndex > -1) {
      var _percentageArray = _percentageStr.split('')
      _percentageArray.splice(_dotIndex + 3, 0, '.')
      _percentageArray.splice(_dotIndex, 1)
      _percentageStr = _percentageArray.join(',').replace(/,/g, '')
    }

    var _result = parseFloat(_percentageStr)
    return _result
  }

  getTaxRate2Display() {
    return {
      qst: this.qst,
      gst: this.gst,
      gstDisplay: this.toPercentage(this.gst),
      qstDisplay: this.toPercentage(this.qst),
    };
  }

}
