import { NgModule, CUSTOM_ELEMENTS_SCHEMA, APP_INITIALIZER } from '@angular/core';
import { registerLocaleData } from '@angular/common';
import zh from '@angular/common/locales/zh';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { FormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';

import { NgZorroAntdModule, NZ_I18N, zh_CN } from 'ng-zorro-antd';

import { ConfigurationModule, ConfigurationProvider, LoginModule, AuthenticationModule, FirebaseModule } from '@hicity/hicity-cms-lib';

import { QRCodeModule } from 'angularx-qrcode';
import { SharedModule } from './shared/shared.module';

import { AppRoutingModule } from './app.routing.module';

import { AppComponent } from './app.component';
import { LoginComponent } from './login/login.component';
import { NavbarComponent } from './navbar/navbar.component';

import { ConfigFromApp, ConfigurationStore, initApp } from './shared/services/configuration';

registerLocaleData(zh);

export function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/data/i18n/', '.json');
}
@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    NavbarComponent,
  ],
  imports: [
    BrowserModule,
    FormsModule,
    HttpClientModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    NgZorroAntdModule,
    QRCodeModule,
    SharedModule,
    // LocationModule,
    ConfigurationModule.forRoot({
      config: {
        provide: ConfigurationProvider,
        useClass: ConfigFromApp
      }
    }),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: (createTranslateLoader),
        deps: [HttpClient],
      }
    }),
    LoginModule,
    AuthenticationModule.forRoot(),
    FirebaseModule.forRoot(),
  ],
  entryComponents: [
  ],
  providers: [
    { provide: NZ_I18N, useValue: zh_CN },
    {
      provide: APP_INITIALIZER,
      useFactory: initApp,
      multi: true,
      deps: [ConfigurationStore, HttpClient]
    }
    // { provide: RouteReuseStrategy, useClass: AppRoutingCache }
  ],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class AppModule { }
