import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';
import * as firebase from 'firebase';

@Pipe({
  name: 'DateFormat'
})
export class DateFormatPipe implements PipeTransform {
  transform(date: any, formatFromNow = true, defaultDate?: any): any {
    try {
      var _date
      if (!date)
        _date = new Date(defaultDate)
      else if (date instanceof Date)
        _date = date
      else if (typeof (date) == 'number')
        _date = new Date(date)
      else if (typeof (date) == 'object')
        _date = (typeof date._seconds != "undefined" || typeof date.seconds != "undefined") ? new firebase.firestore.Timestamp((typeof date._seconds != "undefined" ? date._seconds : date.seconds), (typeof date._nanoseconds != "undefined" ? date._nanoseconds : date.nanoseconds)).toDate() : date.toDate()
      else if (typeof (date) == 'string')
        _date = date
      else
        _date = date.toDate()
      return formatFromNow ? moment(_date).fromNow() : _date;
    } catch (error) {
      console.error(error.message)
    }
  }
}
