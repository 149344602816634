import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'OrderColor'
})
export class OrderColorPipe implements PipeTransform {
  transform(order: any, args?: any): any {
    if (order.reserve == true) {
      return 'indianred';
    } else if (order.deliveryMode == 0) {
      return '#ff8000';
    } else if (order.inStore == true) {
      return '#056e31';
    } else {
      return '#000';
    }
  }
}
