import { Component, OnInit, ViewEncapsulation } from "@angular/core";
// import { ViewEncapsulation } from "@angular/core";
import { Router } from "@angular/router";

import { AuthenticationService, LocationService, MethodService, Role } from "@hicity/hicity-cms-lib";

import { ConfigurationStore } from "../shared/services/configuration";

import { environment } from "src/environments/environment";

@Component({
  selector: 'app-login',
  encapsulation: ViewEncapsulation.None,
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  isProd: boolean;
  firebaseConfig: any;

  spinning: boolean;
  spinningTip: string;

  popularList: any;
  currentLocation: any;

  constructor(
    private router: Router,
    private configurationStore: ConfigurationStore,
    private location: LocationService,
    private auth: AuthenticationService,
    private method: MethodService
  ) {
    this.location.currentLocation.subscribe(currentLocation => {
      this.currentLocation = currentLocation;
    });
  }

  ngOnInit() {
    this.isProd = environment.production;
    this.currentLocation = this.location.currentLocationValue;
  }

  login(logged: any) {
    this.currentLocation = this.location.currentLocationValue;

    if (!(typeof logged === 'boolean' && logged)) {
      switch (logged) {
        case 'auth/user-not-found':
          alert('用户不存在，请联系管理员！');
          break;
        case 'auth/wrong-password':
          alert('用户名/密码错误，请联系管理员！');
          break;
        case 'permission-denied':
          alert('无权访问，请联系管理员！');
          break;
        case 'region-permission-denied':
          alert('无权访问该区域，请切换区域后重试或联系管理员！');
          break;
        default:
          alert('登录被拒绝，请联系管理员！');
          break;
      }

      return;
    }

    const currentUser = this.auth.currentUserValue;
    if (currentUser && Array.isArray(currentUser.role)) {
      // 获取登录用户在所选城市的角色及系统管理员/超级管理员角色
      const currentLocationRole = currentUser.role.filter(v => v.cityId && v.cityId === this.currentLocation.cityId || !v.cityId);
      const currentLocationRoleIds = currentLocationRole.map(v => v.roleId);

      let userRole = {};
      if (currentLocationRole.findIndex(r => r.section === 'global' || r.section === 'all') !== -1) {
        userRole = currentLocationRole.find(r => r.section === 'global' || r.section === 'all');
      }

      // 当前用户无roles中所列出权限且guard不包含route所允许范围
      const noPermission = currentLocationRoleIds.every(v => ![Role.SystemAdmin, Role.SuperAdmin, Role.RegionAdmin].includes(v)) && (!this.method.isNullOrEmpty(userRole) && !userRole['permissionGuard'].includes('order-scheduling') || this.method.isNullOrEmpty(userRole));

      // 有permission或者有depends权限
      if (!noPermission) {
        this.configurationStore.setConfig(this.currentLocation.config);
        this.router.navigate(['']);
      } else {
        this.router.navigate(['permission-deny']);
      }
    } else {
      this.router.navigate(['login']);
    }

  }

}
