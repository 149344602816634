import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'foodDiscount'
})
export class FoodDiscountPipe implements PipeTransform {
  transform(price, discount) {
    discount = parseFloat(discount);
    if (price && discount != 1) {
      price = Math.round(price * discount * 100) / 100;
    }
    return price;
  }
}
